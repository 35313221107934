import {useLocale} from "../../shared-services/translate";
import axios from "axios";

const productsApi = "/api/products.json";

// Set of parameter conversion keys: if null value, they are simply deleted from the params.
const optionKeyConversions = {
  namedSet: "named_set",
  productIds: "ids",
  sortMode: "sort_mode",
  currentZipcode: "zipcode",
  productID: "product_id",
  limitTo: "limit_to",
  excludeIds: "exclude_ids",
  allowCartItems: "allow_cart_items",
  minProducts: null,
  itemReferrer: null,
  onError: null
};

const useProductsFetcher = () => {
  const locale = useLocale();

  // Specific fetchers:
  const loadCategoryTrends = async(options = {categoryId: null, limitTo: 4, excludeIds: []}) => {
    const namedSet = "trending_items";
    const facetName = "categories.id";
    const facetValue = options?.categoryId;
    const requestParams = {namedSet, facetName, facetValue, ...options};

    return await loadProducts(requestParams);
  };

  const loadArbitrarySet = async(options = {}) => {
    return await loadProducts(options);
  };

  // Base product fetcher:
  const loadProducts = async(options = {}) => {
    let params = {
      locale,
      template: "product_in_catalog"
    };

    const {onError, minProducts} = options;

    Object.keys(optionKeyConversions).forEach(key => {
      if (options[key]) {
        if (optionKeyConversions[key]) params[optionKeyConversions[key]] = options[key];
        delete options[key];
      }
    });

    params = {...params, ...options};

    try {
      const response = await axios.get(productsApi, {params});
      let products = response?.data?.products || [];

      if (products.length < minProducts) return [];
      else {
        if (options?.limitTo) products = products.slice(0, options.limitTo);
        if (options?.itemReferrer) {
          products.forEach(product => {
            product.listItemReferrer = options.itemReferrer;
          });
        }
        return products;
      }
    } catch (error) {
      console.error("Error loading products", error);
      if (onError) onError(error);

      return [];
    }
  };

  return {
    loadProducts,
    loadCategoryTrends,
    loadArbitrarySet
  };
};

export default useProductsFetcher;

const ThemeFarmy = {
  name: "farmy",
  fonts: {
    body: "",
    product_item: { // minNameLength: fontSize (in pixels)
      full: {
        70: 13,
        55: 15,
        45: 17,
        0: 18
      },
      mobile_cards: {
        55: 13,
        40: 15,
        0: 17
      },
      other: {
        65: 12,
        50: 13,
        0: 15
      }
    },
    product_ribbon: {
      full: {
        0: 100,
        10: 85,
        15: 80,
        20: 70,
        25: 65,
        30: 60
      },
      mobile_cards: {
        0: 120,
        10: 95,
        13: 85,
        17: 80,
        20: 70
      }
    },
    fontSizes: {
      body: 16,
      bodyBigger: 18,
      h1: 28,
      h2: 24,
      h3: 22
    }
  },
  product_cards: {
    full: {
      cardWidth: "260px"
    },
    mobile_cards: {
      cardWidth: "165px"
    },
    cart: {
      cardWidth: "100%"
    }
  },
  colors: {
    textColor: "#333333",
    textBodyColor: "#333333",
    muteTextColor: "#999999",
    priceColor: "#32702e",
    successColor: "#32702e",
    mutedBg: "#cccccc",
    primaryColor: "#107433",
    primaryBg: "#F4991A",
    discountColor: "#F23000",
    info: "orange",
    danger: "red",
    farmy: {
      orange: "#F4991A",
      green: "#107433",
      lightgreen: "#179F38",
      grey: "#333333",
      lightgrey: "#999999",
      lighterGrey: "#aaaaaa",
      veryLightGrey: "#cccccc",
      farmyFamilyBg: "#E58D61",
      blue: "#3B5AC1"
    }
  },
  breakPoints: {
    xxs: 330,
    xs: 480,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1400
  },
  contentWidth: "1160px",
  borderRadius: {
    sm: "4px",
    md: "8px",
    lg: "12px",
    xl: "16px"
  },
  cartThumbs: {
    xxs: {
      width: "65px",
      height: "65px"
    },
    xs: {
      width: "95px",
      height: "70px"
    },
    sm: {
      width: "75px",
      height: "65px"
    }
  },
  cartBreakPoints: {
    xs: 480,
    sm: 890
  },
  cartSidebar: {
    defaultWidth: "200px"
  },
  modals: {
    basicStyles: {
      maxWidth: "440px",
      padding: "15px"
    }
  },
  columns: {
    gaps: {
      xs: "5px",
      sm: "10px",
      md: "15px",
      lg: "20px"
    }
  },
  transitionTime: {
    fastest: "100ms",
    fast: "300ms",
    medium: "600ms",
    slow: "1000ms",
    slowest: "1500ms"
  }
};

export default ThemeFarmy;

import React, {lazy, Suspense, useRef} from 'react';
import * as _ from "underscore";
import {trackEvent} from "../../services/tracking-helper";

const propsAreEqual = (oldProps, newProps) => {
  return oldProps.product.id === newProps.product.id &&
    oldProps.layout === newProps.layout;
};

export const RjsProductItem = React.memo((props) => {
  // Constants
  const {
    theme,
    product,
    listColumnCount,
    dimensions: listDimensions,
    ItemViewTemplate,
    ItemViewPlaceholder,
    onProductClick,
    positionInList,
    callerLocation,
    itemStyles
  } = props;
  const price = getPrice();
  const ref = useRef();

  // Variables
  const layout = props.layout || (window.isMobile ? "mobile_cards" : "full")
  const nameLengthModifier = "" // use for shrinking long names
  const supplierNameLengthModifier = "" // use for shrinking long names
  // TODO: Calculate name classes (or letter-spacing directly?) for very long names

  const unavailable = product.disabled_sale || !product.available_to_add_to_cart;
  const availableButNotAddableModifier = unavailable ? 'available-soon' : '';

  const onVisitProduct = (eventName, callback = null) => {
    trackEvent(eventName, {product, positionInList, type: "algolia"});
    window.productFromSearch = {queryID: product?.query_id, indexName: product?.index_name};
    if (callback) callback();
  };

  function getVariants() {
    return _.chain(product.variants).select(v => !v.is_master).value().sort()
  }
  function baseVariantName() {
    return product.variants.find(variant => variant.is_base)?.label;
  }
  function getPrice() {
    const variants = getVariants();
    if (variants.length > 0 && variants[0].price != null) {
      return variants[0].price.toFixed(2);
    }

    if (product.price != null) {
      return product.price.toFixed(2);
    }

    return "0.00";
  }

  // Returns product title font-size according to theme values.
  function titleFontSize() {
    const styleKey = theme.fonts.product_item && Object.keys(theme.fonts.product_item).indexOf(layout) > -1 ? layout : 'other'
    const fontSizes = theme.fonts.product_item[styleKey];
    const nameLength = product.name ? product.name.length : 0;
    const finalSize = pickSizeFromObject(fontSizes, nameLength);

    return finalSize ? `${finalSize}px` : 'inherit'
  }

  function pickSizeFromObject(fontSizes, nameLength) {
    const sizes = _(_(_(_(_.chain(fontSizes))
      .keys())
      .map((key) => parseInt(key)))
      .select((minLength) => nameLength > minLength))
      .value();

    return sizes.length > 0 ? fontSizes[_(sizes).max()] : null
  }

  const parentProps = {
    ref,
    theme,
    product,
    listDimensions,
    listColumnCount,
    price,
    layout,
    nameLengthModifier,
    supplierNameLengthModifier,
    baseVariantName,
    titleFontSize,
    availableButNotAddableModifier,
    unavailable,
    onProductClick,
    positionInList,
    onVisitProduct,
    callerLocation,
    itemStyles
  };

  return (
    <Suspense fallback={<ItemViewPlaceholder/>}>
      <ItemViewTemplate {...parentProps}/>
    </Suspense>
  );
}, propsAreEqual);

export default RjsProductItem;

export const getCaptchaToken = async(captchaAction) => {
  try {
    if (typeof grecaptcha === "undefined" || !grecaptcha.enterprise) {
      throw new Error("reCAPTCHA is not loaded");
    }

    return await grecaptcha.enterprise.execute(window.recaptchaSiteKey, {action: captchaAction});
  } catch (error) {
    console.error(error);
    return null;
  }
};

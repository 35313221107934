import useProductsFetcher from "./useProductsFetcher";

const useSets = () => {
  const {
    loadCategoryTrends,
    loadArbitrarySet
  } = useProductsFetcher();

  const sets = {
    categoryTrends: async(options = {}) => {
      return await loadCategoryTrends(options);
    },
    default: async(options) => {
      return await loadArbitrarySet(options);
    }
  };

  return sets;
};

const useNamedSets = (implicitName) => {
  const sets = useSets();

  const fetchNamedSet = async(namedSet = implicitName, options) => {
    const set = sets[namedSet] || sets.default;
    if (!set) return [];

    return await set({namedSet, ...options});
  };

  return fetchNamedSet;
};

export default useNamedSets;
